.tbl_sec table{
    width: 600px;
    margin-top: 30px;
}

.title_row{
    font-weight: bold;
}

.break {
    flex-basis: 100%;
    height: 0;
}


.section{
    margin-top: 0cm;
    display: flex;
    align-items: center;
    justify-content:center;
    flex-direction: column; 
}

.note_section{
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content:center;
    margin-bottom: 30px;
    flex-direction: column; 
}

.note{
    width: 600px;
    margin-bottom: 15px;
}

.note_body{

    margin-top: 5px;
    padding: 5px 5px 5px 5px;
    border: 1px solid #3333;
}

.note_body p{
    min-height: 9px;
}
.notes_form{
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content:center;
    margin-bottom: 30px;
    flex-direction: column;
}

.note_timestamp{
    font-size: 12px;
    font-style: italic;
}

.notes_form button {
    margin-right:20px;
}

.status_msg_con{

    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content:center;
    border: 1px solid black;
    background-color: #66d188;
    height: 50px;
    padding: 10px 10px 10px 10px;
    width: 600px;
    margin-top: 10px;
}

.fail{
    background-color: #ff9966;
}

.info_section{
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.info_section .innersection{
    width: 590px;    
    border: 1px solid lightgrey;    
    padding: 5px 5px 5px 5px;
}

.info_section h1 {
    font-size: 12pt;
    text-align: center;
    font-weight: bold;
    width: 600px;
}

.info_section p {
    width: 600px;
}