.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  .countBlock{
    width: 300px;
    height: 300px;
    border: 1px solid black;
    display: flex;
    flex-flow: column;
  }
  .countBlockTitle{
    border-bottom: 1px solid black;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .countBlockContent{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 50px;
  }
  
  .countBlockContent .info{
  
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 5px 0px 5px;
  }
  
  .countBlockContent .infosmall{
    font-size: 13px;
    padding: 0px 5px 0px 5px;
  }
  
  .listBlock{
    width: 300px;
    min-height: 300px;
    border: 1px solid black;
    display: flex;
    flex-flow: column;
  }
  .listBlockTitle{
    border-bottom: 1px solid black;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .listBlockContent{
    display: flex;
    flex-flow: column;
    justify-content: left;
    align-items: left;
    height: 100%;
    font-size: 0.9em;
    text-transform: capitalize;
  }
  .listBlockContent li{
    padding: 0px 0px 5px 0px;
  
    white-space: nowrap;
    width: 90%;
    text-overflow: clip;
  }
  
  .sec{
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content:center;
    margin-bottom: 10px;
    flex-wrap: wrap;
    width: 100%;
  
    @media (max-width: 1000px) {
      width:100%;
      display: flex;
      justify-content: center;
      display: block;
    }
  }

  .fitler_sec{
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content:center;
    margin-bottom: 20px;
    flex-wrap: wrap;
    width: 100%;
  
    @media (max-width: 1000px) {
      width:100%;
      display: flex;
      justify-content: center;
      display: block;
    }
  }
  
  .filter_field {
  
    @media (max-width: 1000px) {
      margin-bottom: 10px;
      width: 100%;
    }
  
  }
  
  
  .filter_field div{
    
    display: flex;
    align-items: center;
    justify-content:center; 
  
  }
  
  
  .filter_field_in{
    margin-left: 10px; 
    
    @media (max-width: 1000px) {
      margin-left: 0px; 
    }
  }
  
  .tblinf table tbody tr td{
  
    border: none !important;
  
  }
  
  .tblinf table tbody tr{
    width: 100%;
  }
  
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .closer{
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  .field{
    /* position: absolute; */
  }